import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <h1>
        Not Found{" "}
        <span role="img" aria-label="red cross mark">
          ❌
        </span>
      </h1>
      <p>
        You just hit a route that doesn&#39;t exist{" "}
        <span role="img" aria-label="hushed face emoji">
          😯
        </span>{" "}
        let me know if this is an error on{" "}
        <a
          href="https://twitter.com/____baran"
          target="_blank"
          rel="noreferrer"
        >
          Twitter.
        </a>
      </p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
